import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { Banner, Head, Layout, Article } from "../components"

export const ArticleListPageTemplate = ({ title, articles }) => (
  <>
    <Banner interior title={title} />
    {articles.map(({ node }) => {
      const { frontmatter, excerpt, fields } = node
      const { title: articleTitle, image, date } = frontmatter
      return (
        <Article
          key={`t-${articleTitle}-${date}`}
          teaser
          title={articleTitle}
          link={`/actualites${fields.slug}`}
          content={excerpt}
          image={
            image && image.childImageSharp ? image.childImageSharp.fluid : null
          }
        />
      )
    })}
  </>
)

ArticleListPageTemplate.propTypes = {
  title: PropTypes.string,
  tags: PropTypes.any,
  total: PropTypes.number,
  currentPage: PropTypes.number,
  basePath: PropTypes.string,
  articles: PropTypes.array,
}

const ArticleListPage = ({ data }) => {
  const articles = data.allMarkdownRemark.edges
  const title = "Actualités"
  return (
    <Layout>
      <Head title={title} />
      <ArticleListPageTemplate title={title} articles={articles} />
    </Layout>
  )
}

ArticleListPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default ArticleListPage

export const pageQuery = graphql`
  query articleListPageQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "article" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt
          frontmatter {
            title
            image {
              childImageSharp {
                fluid(maxWidth: 570, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
